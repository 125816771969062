
import { useState,useEffect,useContext } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "axios";
import pfContext from '../../context/PfContext';
import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";

export function FilterDropDownSingle(props) {
  const { user } = useAuth();
const context=useContext(pfContext);
const cwid=(user!==null && user.user_cwid!==null)?user.user_cwid:"";
const endpoint = process.env.REACT_APP_ENDPOINT;
const key=process.env.REACT_APP_API_KEY;
//  const [selectedOptions,setSelectedOptions]=useState([]);
const [allOptions,setAllOptions]=useState([]);
const [dataSet,setDataSet]=useState([]);
const [isAllSelected,setIsAllSelected]=useState(props.isAllSelected);
const [unCheckedValue,setUnCheckedValue]=useState([]);
//const [defaultValue, setDefaultValue] = useState("");
const [defaultColor,setDefaultColor]=useState("white");

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '5px',
        border: '2px solid #ccc',
        boxShadow: state.isFocused ? '0 0 0 2px #3699FF' : null,
        height:"30px",
        backgroundColor:defaultColor,
        width:"16rem"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#3699FF' : null,
        color: state.isFocused ? 'white' : null,
        
    }),
}

useEffect(()=>{
  if(props.value?.length==0)
  {
    setDefaultColor("white");
  }
  else
  {
    // console.log("props.value",props.value);
    setDefaultColor("lightyellow");
  }
},[props.value])

// create JSON Array from response data
function getData(data,type,page) {
  const jsonArray = [];
  const data1 = data.data.data[`get${type}Multi`][`${type}_MasterData`];
  if (data1 !== null && data1.length > 0) {
    //   if(page===1)
    //  { jsonArray.push({ code: "All", name: "select all",isChecked:false });
      
    //   }
      for (let i = 0; i < data1.length; i++) {
          const string1 = data1[i].replace("[", "").replace("]", "");
          const code = string1.split(",")[0];
          const name = string1.split(",")[1];
          if (name !== null) 
          jsonArray.push({ code: code, name: `${code}-${name}`});
      }
  }

  return jsonArray;
 
}



const getSubQuery=(type,searchQuery)=>{

  let gsmCode=(context.gsmNetwork)?.map((item)=>(item.code));
  let locCode=(context.location)?.map((item)=>(item.code));
  let comCode=(context.com)?.map((item)=>(item.code));
  let proCode=(context.product)?.map((item)=>(item.code));
  let pipCode=(context.pipeline)?.map((item)=>(item.code));


  let subQuery="";
  if(type==="Brand" )
  subQuery=`,CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${(searchQuery==="")?(""+pipCode.toString()+""):""}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
  else if(type==="GSM")
  subQuery=`,Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${(searchQuery==="")?(""+gsmCode.toString()+""):""}", Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
  else if(type==="CoM")
  subQuery=`, Brand_Sel_All:${context.isBrandSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Product_Sel_All:${context.isProductSelectAll},Brand_Values:"${pipCode.toString()}", CoM_Values: "${(searchQuery==="")?(""+comCode.toString()+""):null}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${proCode.toString()}"`;
   else if(type==="Location")
   subQuery=`,Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Product_Sel_All:${context.isProductSelectAll}, Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${(searchQuery==="")?(""+locCode.toString()+""):""}", Product_Values: "${proCode.toString()}"`;
 else if(type==="Product")
 subQuery=`, Brand_Sel_All:${context.isBrandSelectAll},CoM_Sel_All:${context.isCoMSelectAll},GSM_Sel_All:${context.isGsmSelectAll},Location_Sel_All:${context.isLocationSelectAll},Brand_Values:"${pipCode.toString()}", CoM_Values: "${comCode.toString()}",  GSM_Values: "${gsmCode.toString()}",Location_Values: "${locCode.toString()}", Product_Values: "${(searchQuery==="")?(""+proCode.toString()+""):""}"`;
 
  //if(type==="Brand")
  return subQuery;
}
// get Filter data
const loadOptions = async (searchQuery, loadedOptions, { page,type,updateOptions
}) => {
  
    let offset = 0;
   // const cwid="EFJXN"
    //console.log(`loading page:${page} - limit: 10`);
    if (page > 1) offset = page * 10 - 10;

    const response = await axios({
        url: endpoint,
        method: "POST",
        headers: { "x-api-key": key },

        data: {
            query: `query MyQuery {
        get${type}Multi(limit: 10, search_term: "${searchQuery}",CWID:"${cwid}",offset: ${offset},
        ${type}_Sel_All:${isAllSelected}, ${getSubQuery(type,searchQuery)}
       ) {
          No_Of_Pages
          ${type}_MasterData
        }
      }
      `,
        },
    });
    const responseJSON = getData(response,type,page);
   
    updateOptions(responseJSON,page,type);
    return {
        options: responseJSON,
        hasMore: responseJSON.length >= 1,
        additional: {
            page: searchQuery ? 2 : page + 1,
            type:type,
            updateOptions:updateOptions
        },
    };
};



useEffect(()=>{
  if(dataSet)
  {
   const total=[...allOptions,...dataSet];
 
   const ids = total.map(({ code }) => code);
   const filtered = total.filter(({ code }, index) =>
    !ids.includes(code, index + 1));
 
    setAllOptions(filtered);

  }

},[dataSet]);

const updateOptions=(items,page,type)=>{
  setDataSet(items);
//   if(page===1){
//     //console.log(items.find((o) => o.code === props.defaultOptionValue));
//   !props.defaultValue && props.setDefaultValue(items.find((o) => o.code === props.defaultOptionValue)); 


//   props.updateSteps(type,"select-option");

//   }
}


 

const onChange=(value,event)=>{

    //console.log("value",value);
    //console.log("value",event);
if(event.action==="select-option")
  {

    if((props.tableName!="" && props.tableName!=undefined) || props.isSaveDone)
				{
				 	props.setIsGsmChangedAfterPreview(true);
				}
				else
        {
        setUnCheckedValue(value);
        props.onChange([value]);
       // props.setSelectedOptions(value);
      setDefaultColor("lightyellow");
      props.updateSteps(props.type,event.action);
      }
  }
   else if(event.action==="clear")
   {
    if((props.tableName!="" && props.tableName!=undefined) || props.isSaveDone)
    {
       props.setIsGsmChangedAfterPreview(true);
    }
    else
    {
    props.setSelectedOptions([])
   setIsAllSelected(false); 
  // props.setIsAllSelected(false);
   props.onChange([]);
   setUnCheckedValue([]);
  // props.setDefaultValue(null);
   setDefaultColor("White");
   props.updateSteps(props.type,event.action);
    }
   }
}



  return (
    <div>
      <AsyncPaginate  
        key={props.dependency}
        debounceTimeout={300}
        hideSelectedOptions={false}
        value={props.value}
        loadOptions={loadOptions}
        isMulti={false}
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}       
        getOptionValue={(option) => option.name}
		    getOptionLabel={(option) => option.name}
        isClearable={true}
        onChange={onChange}
         
      
			// isSearchable={false}
			placeholder={props.title}
			styles={customStyles}
			additional={{
				page: 1,
        type:props.type,
        updateOptions:updateOptions
			}}
      />
    </div>
  );
}