import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useStorageLocation";
import { useMsal } from '@azure/msal-react';
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = accounts.length > 0;
  const from = location.state?.from?.pathname || "/";

  const [isUserManage, setIsUserManage] = useState(false);


  useEffect(() => {
    // console.log(location.pathname)
    if (location.pathname.includes('userManage')) {
      setIsUserManage(true);
    } 
  }, [location]);

  const login = async (data) => {
    setUser(data);
  
    if (isAuthenticated) {
      if (data.access && data.email.trim().toLowerCase() === accounts[0].username.toLowerCase()) {
        navigate(from, { replace: true }); // Navigate to the intended route
      } else {
        navigate("/home", { replace: true }); 
      }
    } else {
      navigate("/home", { replace: true });
    }
  };
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };
  const value = useMemo(() => ({
    user,
    login,
    logout,
    isUserManage
  }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  try{
  return useContext(AuthContext);
  }
  catch(error)
  {
    console.log("Error in Auth");
  }
};